<template>
  <div class="publisher-statistic">
    <div v-show="isLoadingLists" class="publisher-statistic__loader">
      <b-spinner variant="primary" label="Spinning" />
    </div>
    <b-card no-body class="publisher-statistic__filters" :class="{'_loading' : isLoadingLists}">
      <b-row class="my-2 px-2 justify-content-between">
        <b-col sm="4">
          <DropDownFilter :on-change="setFilter"
                          :facets="publishersList"
                          :state="queryObj.publishers"
                          track-by="id"  name="publishers" placeholder="Паблишеры"/>
        </b-col>
        <b-col sm="4">
          <DropDownFilter :on-change="setFilter"
                          :facets="platformsList"
                          :state="queryObj.platforms"
                          track-by="id"  name="platforms" placeholder="Площадки"/>
        </b-col>
        <b-col sm="4">
          <b-button :disabled="isLoadingLists" variant="primary" @click="fetchStatistic">Загрузить</b-button>
        </b-col>
      </b-row>

      <b-row class="my-2 px-3 justify-content-between">
        <b-form-group label="Группировать по">
          <b-form-radio-group
              id="group"
              v-model="queryObj.group"
              :options="groupOptions"
              name="group"
          ></b-form-radio-group>
        </b-form-group>
      </b-row>
      <b-row class="my-2 px-2">
        <b-col sm="4">
          <b-form-group label="Выберите даты">
            <date-range-picker
                :disabled="isLoadingLists"
                id="datepicker"
                ref="picker"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                :maxDate="todayDate"
                autoApply
                v-model="dateRange"
                @update="setDate"
                linkedCalendars
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div ref="scroll">
        <table-lazy :error="!!error" :is-loading="isLoading" :is-loading-more="isLoadingMore">
          <statistics-table v-if="data" :statistics="data.result" :fields="data.field" :sums="data.sum" />
        </table-lazy>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormSelect,
  BRow,
  BSpinner
} from 'bootstrap-vue'
import StatisticsTable from "@/components/StatisticsTable"
import TableLazy from '@/components/TableLazy'
import DropDownFilter from "@/components/DropDownFilter"
import {usePage} from '@/use/page'
import {infiniteScroll} from "@/use/infiniteScroll"
import {fetchFinance} from "@/api/statistics"
import {
  fetchDate,
  fetchPlatformsList,
  fetchPublishersList
} from "@/api/lists"
import {ref} from "@vue/composition-api"
import DateRangePicker from '@lexankh/vue2-daterange-picker'
import {statisticQueries} from "@/use/statisticQueries"
// import LineChart from "@core/components/statistics-cards/LineChart"

export default {
  components: {
    // LineChart,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButtonGroup,
    BButton,
    BFormSelect,
    BFormGroup,
    BFormRadioGroup,
    BFormDatepicker,
    StatisticsTable,
    TableLazy,
    DropDownFilter,
    DateRangePicker
  },
  data() {
    return {
      search: '',
      activeFilter: null,
      campaigns: [],
    }
  },
  setup(_, ctx) {

    const { isLoading, data, error, loadMoreData, isLoadingMore, fetchPageData } = usePage(fetchFinance, _, ctx, 'notPreload')
    const { scroll } = infiniteScroll(loadMoreData, isLoadingMore)
    const { queryObj, fetchStatistic } = statisticQueries(fetchPageData, ctx)

    //lists
    const publishersList = ref([])
    const platformsList = ref([])
    const currentDateString = ref(null)
    const todayDate = ref(null)
    const dateRange = ref({
      startDate: new Date(),
      endDate: new Date()
    })

    const groupOptions = [
      { text: 'Дата', value: 'date' },
      { text: 'Паблишер', value: 'publisher' },
      { text: 'Площадка', value: 'platform' },
    ]

    const isLoadingLists = ref(true)
    Promise.all([
      fetchPublishersList(),
      fetchPlatformsList(),
      fetchDate()])
        .then(values => {
          publishersList.value = values[0].data.data
          platformsList.value = values[1].data.data
          currentDateString.value = values[2].data.current_date

          // set actual date
          todayDate.value = new Date(currentDateString.value)
          dateRange.value.endDate = todayDate.value;
          const weekEarlier = todayDate.value.getDate() - 7
          dateRange.value.startDate.setDate(weekEarlier)

          queryObj.start_date = dateRange.value.startDate.toLocaleDateString('en-CA');
          queryObj.end_date = dateRange.value.endDate.toLocaleDateString('en-CA');

        })
        .finally(() => {
          isLoadingLists.value = false;
        })

    // filter handling

    const setFilter = (value, name) => {
      queryObj[name] = value
    }

    // set date to queryObj
    const setDate = (value) => {
      queryObj.start_date = value.startDate.toLocaleDateString('en-CA');
      queryObj.end_date = value.endDate.toLocaleDateString('en-CA');
    }

    return { isLoading,
      data,
      error,
      loadMoreData,
      isLoadingMore,
      fetchPageData,
      scroll,
      queryObj,
      isLoadingLists,
      publishersList,
      platformsList,
      groupOptions,
      todayDate,
      currentDateString,
      setFilter,
      dateRange,
      fetchStatistic,
      setDate
    }
  },

  methods: {
    resetFields() {
      this.$store.dispatch('statistics/RESET_FIELDS');
    }
  },

  created() {
    this.resetFields();
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@lexankh/vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
<style lang="scss" scoped>
.multiselect, .multiselect__input, .multiselect__single {
  font-size: 14px;
}

.vue-daterange-picker {
  min-width: 100%;
}

.publisher-statistic {
  position: relative;

  &__loader {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 45%;
    width: 100%;
    height: 100%;
  }

  &__filters {

    &._loading {
      filter: blur(4px)
    }
  }
}
</style>

<style lang="scss">
.reportrange-text.form-control {
  padding: 8px 40px 0 8px;
  border: 1px solid #e8e8e8;
}

.custom-control {
  cursor: pointer;
}
</style>
